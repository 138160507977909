import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(VueI18n)


function loadLocaleMessages() {
  const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const i18n = new VueI18n({
  locale: 'cz', // Set the default locale
  fallbackLocale: 'cz', // Fallback locale
  messages: loadLocaleMessages()
})

new Vue({
  render: h => h(App),
  router,
  i18n,
}).$mount('#app')