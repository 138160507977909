<template>
  <div data-role="Header" class="header-header">
    <nav class="header-nav">
      <div class="header-container">
        <router-link to="/" class="header-navlink">
          {{ $t('name') }}
        </router-link>
        <div class="header-menu">
          <router-link :to="{hash: '#about'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.about') }}
          </router-link>
          <router-link :to="{hash: '#services'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.services') }}
          </router-link>
          <router-link :to="{hash: '#prices'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.prices') }}
          </router-link>
          <router-link :to="{hash: '#hww'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.hww') }}
          </router-link>
          <router-link :to="{hash: '#feed'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.feedbacks') }}
          </router-link>
          <router-link :to="{hash: '#contact'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.contact') }}
          </router-link>
        </div>
        <div class="header-container1">
          <div class="header-menu">
            <a @click="setLanguage('en')" class="header-navlink01 Large">
              EN
            </a>
            <a @click="setLanguage('cz')" class="header-navlink01 Large">
              CZ
            </a>
            <a @click="setLanguage('ru')" class="header-navlink01 Large">
              RU
            </a>
          </div>
          <div class="header-container2">
            <primary-pink-button @click="scrollMeTo('contact')" :button="$t('nav.send')" ></primary-pink-button>
          </div>
          <div data-role="BurgerMenu" id="menuToggle">
            <input type="checkbox" id="menuCheck" v-model="isChecked"/>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </nav>

    <div data-role="MobileMenu"  v-if="isChecked" class="header-mobile-menu">

      <div class="header-menu1">
        <router-link :to="{hash: '#about'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
          {{ $t('nav.about') }}
        </router-link>
        <router-link to="#services" :to="{hash: '#services'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
          {{ $t('nav.services') }}
        </router-link>
        <router-link to="#prices" :to="{hash: '#prices'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
          {{ $t('nav.prices') }}
        </router-link>
        <router-link :to="{hash: '#hww'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
          {{ $t('nav.hww') }}
        </router-link>
        <router-link :to="{hash: '#feed'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
          {{ $t('nav.feedbacks') }}
        </router-link>
        <router-link :to="{hash: '#contact'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
          {{ $t('nav.contact') }}
        </router-link>
        <div>
          <a  @click="setLanguage('en')" class="header-navlink01 Large">
            EN
          </a>
          <a  @click="setLanguage('cz')" class="header-navlink01 Large">
            CZ
          </a>
          <a  @click="setLanguage('ru')" class="header-navlink01 Large">
            RU
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryPinkButton from '@/components/primary-pink-button'

export default {
  name: 'Header',
  props: {},
  components: {
    PrimaryPinkButton,
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    scrollMeTo(refName) {
      var element = document.getElementById(refName);
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    anchorHashCheck() {
      this.isChecked = false;
      if (window.location.hash === this.$route.hash) {
        const el = document.getElementById(this.$route.hash.slice(1))
        if (el) {
          window.scrollTo(0, el.offsetTop)
        }
      }
    },
    setLanguage(val) {
      this.$i18n.locale = val
      console.log(this.$i18n.locale);
      window.localStorage.setItem('Language', val)
    },
  },
  mounted() {
    this.anchorHashCheck()
  },
}

</script>

<style scoped>
#menuToggle {
  display: none;
  flex-direction: column;
  position: relative;
  top: 5px;
  left: 10px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: var(--dl-color-secondary-700);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-5px, -15px);
  background: #36383F;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 1;
  transform: rotate(0deg) scale(0, 0);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0px, 12px);
}

#menuToggle input:checked ~ ul {
  transform: none;
}

#menuToggle input:checked ~ .header-mobile-menu {
  display: block;
}
a {
  -webkit-transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;
}
a:hover {
  color: rgb(255, 0, 128) !important;
}
.t-active {
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-header {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  display: flex;
  z-index: 3;
  position: fixed;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}

.header-nav {
  flex: 0 0 auto;
  color: var(--dl-color-secondary-600);
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 .25rem .375rem -.0625rem hsla(0, 0%, 8%, .12), 0 .125rem .25rem -.0625rem hsla(0, 0%, 8%, .07) !important;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: var(--dl-radius-radius-radius40);
  padding-right: var(--dl-space-space-unitandahalfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  backdrop-filter: saturate(200%) blur(30px);
  justify-content: space-between;
  background-color: hsla(0, 0%, 100%, .8) !important;
}

.header-container {
  color: var(--dl-color-gray-black);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-navlink {
  color: var(--dl-color-secondary-700);
  font-weight: 600;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}

.header-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.header-navlink01 {
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink01:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink02 {
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink02:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink03 {
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink03:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink04 {
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink04:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink05 {
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink05:hover {
  color: var(--dl-color-gray-500);
}

.header-text:hover {
  color: var(--dl-color-gray-500);
}

.header-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}


.header-mobile-menu {
  top: 80px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: var(--dl-space-space-unitandahalfunit);
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.header-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-navlink06 {
  color: var(--dl-color-secondary-700);
  font-weight: 600;
  text-decoration: none;
}

.header-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.header-icon2 {
  width: 24px;
  height: 24px;
}

.header-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.header-menu1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}

.header-navlink07 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink07:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink08 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink08:hover {
  color: var(--dl-color-gray-500);
}

.header-navlink09 {
  transition: 0.3s;
  text-decoration: none;
}

.header-navlink09:hover {
  color: var(--dl-color-gray-500);
}

.header-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  flex-direction: column;
}

@media (max-width: 991px) {
  .header-nav {
    max-width: 960px;
  }

  .header-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  #menuToggle {
    display: flex;
  }
}

@media (max-width: 479px) {
  #menuToggle {
    display: flex;
  }

  .header-container2 {
    display: none;
  }

  #menuToggle {
    visibility: visible;
  }
}
</style>
