<template>
  <footer class="footer-footer">
    <div class="footer-container">
      <div class="footer-container1">
        <span class="footer-text">Professional translations</span>
        <span>© Vetal s.r.o. 2021, All Rights Reserved.</span>
      </div>
      <div class="footer-container2">
        <div class="footer-container3">
          <router-link :to="{hash: '#about'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.about') }}
          </router-link>
          <router-link :to="{hash: '#services'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.services') }}
          </router-link>
          <router-link :to="{hash: '#prices'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.prices') }}
          </router-link>
        </div>
        <div class="footer-container4">
          <router-link :to="{hash: '#hww'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.hww') }}
          </router-link>
          <router-link :to="{hash: '#feed'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.feedbacks') }}
          </router-link>
          <router-link :to="{hash: '#contact'}" @click.native="anchorHashCheck" active-class="t-active" class="header-navlink01 Large">
            {{ $t('nav.contact') }}
          </router-link>
        </div>
      </div>
    </div>
    <img alt="image" src="/waves-white.svg" class="footer-image" />
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
  methods: {
    anchorHashCheck() {
      this.isChecked = false;
      if (window.location.hash === this.$route.hash) {
        const el = document.getElementById(this.$route.hash.slice(1))
        if (el) {
          window.scrollTo(0, el.offsetTop)
        }
      }
    },
  },
  mounted() {
    this.anchorHashCheck()
  },
}
</script>

<style scoped>
.footer-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.footer-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  max-width: 1320px;
  justify-content: space-between;
}
.footer-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container2 {
  display: flex;
  justify-content: space-between;
}
.footer-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text02 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text03 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text03:hover {
  color: var(--dl-color-gray-white);
}
.footer-text04 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text04:hover {
  color: var(--dl-color-gray-white);
}
.footer-text05 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text05:hover {
  color: var(--dl-color-gray-white);
}
.footer-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.footer-text06 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text07 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text07:hover {
  color: var(--dl-color-gray-white);
}
.footer-text08 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text08:hover {
  color: var(--dl-color-gray-white);
}
.footer-text09 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text09:hover {
  color: var(--dl-color-gray-white);
}
.footer-container5 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.footer-text10 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text11 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text11:hover {
  color: var(--dl-color-gray-white);
}
.footer-text12 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text12:hover {
  color: var(--dl-color-gray-white);
}
.footer-text13 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text13:hover {
  color: var(--dl-color-gray-white);
}
.footer-image {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .footer-container {
    padding: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-container1 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container4 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .footer-container5 {
    margin-left: var(--dl-space-space-doubleunit);
  }
}
</style>
