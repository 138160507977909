<template>
  <div class="home-container">
    <app-header></app-header>
    <div class="home-hero" id="about">
      <div class="home-container01">
        <div class="home-card">
          <div class="profile">
            <h1 class="home-text home-text HeadingOne">{{ $t('natali') }}</h1>
            <div class="profile-image">
              <img
                  src="/natali.jpeg"
                  style="display: block; height: 100%; border-radius: inherit;  object-position: center center; object-fit: cover; image-rendering: auto;">
            </div>
          </div>
          <h2 class="home-text01 HeadingTwo">{{ $t('hero.text-header') }}</h2>
          <div class="home-container02">

            <div class="framer-1yj4cz1">

              <div style="position: absolute; border-radius: inherit; inset: 0px;">
                <img
                    src="/background-1-1.png"
                    style="display: block; height: 100%; width: 100%; border-radius: inherit;    filter: grayscale(1);  object-position: center center; object-fit: cover; image-rendering: auto;">
              </div>

              <article class="grid1">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </article>
            </div>
          </div>
          <span class="home-text02 HeadingThree">
           {{ $t('hero.text-about') }}
          </span>
        </div>
      </div>
    </div>
    <img alt="image" src="/curved6-1500h.jpg" class="home-image2"/>
    <section class="home-features" id="services">
      <div class="home-container03">
        <h1 class="home-text03 home-text23 home-text04">{{ $t('services.name') }}</h1>
        <span class="home-text04">
          <span class="home-text05">{{ $t('services.text-header') }}</span>
        </span>
      </div>
      <div class="home-container24">
        <div class="service-card-container">
          <feature-card
              :new_prop="$t('services.service-1')"
              image_src="/person1.svg"
          ></feature-card>
          <feature-card
              :new_prop="$t('services.service-2')"
              image_src="/person1.svg"
          ></feature-card>
          <feature-card
              :new_prop="$t('services.service-3')"
              image_src="/person1.svg"
          ></feature-card>
          <feature-card
              :new_prop="$t('services.service-4')"
              image_src="/person1.svg"
          ></feature-card>
        </div>
        <div class="service-card-container">
          <feature-card
              :new_prop="$t('services.service-5')"
              image_src="/person1.svg"
          ></feature-card>
          <feature-card
              :new_prop="$t('services.service-6')"
              image_src="/person1.svg"
          ></feature-card>
          <feature-card
              :new_prop="$t('services.service-7')"
              image_src="/person1.svg"
          ></feature-card>
          <feature-card
              :new_prop="$t('services.service-8')"
              image_src="/person1.svg"
          ></feature-card>
        </div>
      </div>
    </section>
    <section class="home-container04" id="prices">
      <div class="home-container05">
        <h1 class="home-text06 home-text23 home-text07">{{ $t('prices.name') }}</h1>
        <span class="home-text07">{{ $t('prices.text-header') }}</span>
      </div>
      <div class="home-pricing">
        <!--        price1-->
        <div class="home-card2">
           <span class="home-text60 ">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container06"><h1 class="home-text-price">350.00,-</h1></div>
            <span class="home-text09">
             <div style="margin-top: 20px">
                <span class="home-text15">{{ $t('prices.price-1') }}</span><br>
               <span class="home-text15">{{ $t('prices.price-1-1') }}</span>
              </div>
            </span>
          </div>
        </div>
        <!--        price2-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">400.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-2') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price3-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">300.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-3') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price4-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">380.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-4') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price5-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">80.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-5') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price6-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">50.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-6') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price7-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">110.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-7') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price8-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">180.00,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-8') }}</span><br>
              </div>
            </span>
          </div>
        </div>
        <!--        price9-->
        <div class="home-card2">
           <span class="home-text60">
             {{ $t('prices.fn-1') }}
            </span>
          <div class="home-card1">
            <div class="home-container07">
              <span class="home-text-price">+70%,-</span>
            </div>
            <span class="home-text09">
             <div>
                <span class="home-text15">{{ $t('prices.price-9') }}</span><br>
              </div>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="home-testimonials" id="hww">
      <div class="home-container09">
        <div class="home-container10">
          <div class="home-steps">
            <h1 class="home-text23 HeadingOne home-text07"><span>{{ $t('hww.name') }}</span></h1>
            <span class="home-text24 Lead">
              <br/>
              <span></span>
            </span>
            <div class="home-container11">
              <div class="home-step">
                <div class="home-container12">
                  <div class="home-line"></div>
                  <div class="home-container13">
                    <img data-v-7eb2bc79="" src="/group.svg" class="home-icon">
                  </div>
                  <div class="home-line1"></div>
                </div>
                <div class="home-container14">
                  <h1 class="HeadingTwo home-text15" style="text-wrap: nowrap">{{ $t('hww.step1h') }}</h1>
                  <span class="step-text">
                    <span>
                      {{ $t('hww.step1t') }}
                    </span>
                    <br/>
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="home-step">
                <div class="home-container12">
                  <div class="home-line2"></div>
                  <div class="home-container13">
                    <img data-v-7eb2bc79="" src="/calc.svg" class="home-icon">
                  </div>
                  <div class="home-line3"></div>
                </div>
                <div class="home-container14">
                  <h1 class="HeadingTwo home-text15"><span>{{ $t('hww.step2h') }}</span></h1>
                  <span class="step-text">
                    <span>
                      {{ $t('hww.step2t') }}
                    </span>
                    <br/>
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="home-step">
                <div class="home-container12">
                  <div class="home-line4"></div>
                  <div class="home-container13">
                    <img data-v-7eb2bc79="" src="/snadclock.svg" class="home-icon">
                  </div>
                  <div class="home-line5"></div>
                </div>
                <div class="home-container14">
                  <h1 class="HeadingTwo home-text15"><span>{{ $t('hww.step3h') }}</span></h1>
                  <span class="step-text">
                    <span>
                      {{ $t('hww.step3t') }}
                    </span>
                    <br/>
                    <span></span>
                  </span>
                </div>
              </div>
              <div class="home-step">
                <div class="home-container12">
                  <div class="home-line6"></div>
                  <div class="home-container13">
                    <img data-v-7eb2bc79="" src="/money.svg" class="home-icon">
                  </div>
                  <div class="home-line7"></div>
                </div>
                <div class="home-container14">
                  <h1 class="HeadingTwo home-text15"><span>{{ $t('hww.step4h') }}</span></h1>
                  <span class="step-text">
                    <span>
                      {{ $t('hww.step4t') }}
                    </span>
                    <br/>
                    <span></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img alt="image" src="/bottom.svg" class="home-bottom-wave-image"/>
      <img alt="image" src="/waves-white.svg" class="home-image3"/>
      <img alt="image" src="/top.svg" class="home-top-wave-image"/>
    </section>
    <section class="home-contaier" id="feed">
      <div class="home-container05">
        <h2 class="home-text55 home-text23 home-text04">{{ $t('feedbacks.name') }}</h2>
      </div>
      <div class="home-divider">
        <div class="home-testimonial">
          <h1 class="feature-card-text1 HeadingTwo">
            <span>{{ $t('feedbacks.text-header') }}</span>
            <br/>
            <span></span>
          </h1>
          <div class="home-container26">
            <testimonial-card1
                picture_src="/teams_photo1.jpg"
                rootClassName="rootClassName2"
                name="Martin Willson"
                :quote="$t('feedbacks.f1')"
            ></testimonial-card1>
            <testimonial-card1
                picture_src="teams_photo_3.jpg"
                rootClassName="rootClassName"
                name="Anna Serduk"
                :quote="$t('feedbacks.f2')"
            ></testimonial-card1>
            <testimonial-card1
                picture_src="teams_photo_2.jpg"
                rootClassName="rootClassName1"
                name="Kateřina Horáková"
                :quote="$t('feedbacks.f3')"
            ></testimonial-card1>
          </div>
        </div>
      </div>
    </section>
    <section class="t-cover" id="contact">
      <div class="t-cover__carrier"></div>
      <div class="t-cover__filter"></div>
      <div class="home-contaier">
        <div class="profile-contact" style="z-index: 1">
          <div class="profile-container09">
            <div class="profile-form">
              <h2 class="profile-text18 HeadingTwo"><span>{{ $t('contact.form.name') }}</span></h2>
              <form class="profile-form1" @submit.prevent="submitForm">
                <label class="profile-text21 Label">{{ $t('contact.form.lbName') }}</label>
                <input
                    type="text"
                    :placeholder="$t('contact.form.inName')"
                    class="profile-textinput Small input"
                    name="Name"
                    v-model="formData.name"
                    required
                />
                <label class="profile-text21 Label">{{ $t('contact.form.lbEmail') }}</label>
                <input
                    type="Email"
                    :placeholder="$t('contact.form.inEmail')"
                    class="profile-textinput1 Small input"
                    name="Email"
                    v-model="formData.email"
                    required
                />
                <label class="profile-text21 Label">{{ $t('contact.form.lbPhone') }}</label>
                <input
                    type="text"
                    :placeholder="$t('contact.form.inPhone')"
                    class="profile-textinput1 Small input"
                    name="Phone"
                    v-model="formData.phone"
                    required
                />
                <label class="profile-text23 Label">{{ $t('contact.form.lbService') }}</label>
                <select name="Service name" v-model="formData.service" required class="t-select input Small"
                        aria-required="true"
                        style="background-color:#ffffff;border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;">
                  <option :value="$t('prices.price-1')" style="background-color:#ffffff">{{ $t('prices.price-1') }}
                  </option>
                  <option :value="$t('prices.price-1-1')" style="background-color:#ffffff">
                    {{ $t('prices.price-1-1') }}
                  </option>
                  <option :value="$t('prices.price-2')" style="background-color:#ffffff">{{ $t('prices.price-2') }}
                  </option>
                  <option :value="$t('prices.price-3')" style="background-color:#ffffff">{{ $t('prices.price-3') }}
                  </option>
                  <option :value="$t('prices.price-4')" style="background-color:#ffffff">{{ $t('prices.price-4') }}
                  </option>
                  <option :value="$t('prices.price-5')" style="background-color:#ffffff">{{ $t('prices.price-5') }}
                  </option>
                  <option :value="$t('prices.price-6')" style="background-color:#ffffff">{{ $t('prices.price-6') }}
                  </option>
                  <option :value="$t('prices.price-7')" style="background-color:#ffffff">{{ $t('prices.price-7') }}
                  </option>
                  <option :value="$t('prices.price-8')" style="background-color:#ffffff">{{ $t('prices.price-8') }}
                  </option>
                </select>

                <label class="profile-text21 Label">{{ $t('contact.form.lbPages') }}</label>
                <div class="t-input-block"
                     style="border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;">
                  <div class="t-range__wrapper">
                    <input ref="input" name="Page count" value=0 class="t-range" v-model="sliderValue" type="range"
                           min="1" max="100" style="width: 100%;"/>
                    <p>{{ sliderValue }}</p>
                    <div class="t-range__interval-txt-wrapper">
                    </div>
                  </div>

                </div>

                <a  @click="showModal">* {{ $t('contact.form.fn') }}</a>
                <div class="profile-container10">

                  <div class="profile-container11">
                    <primary-blue-button text="send message" button-type="submit" class="pageclip-form__submit"></primary-blue-button>
                  </div>
                </div>
              </form>
            </div>
            <div class="profile-info">
              <div class="profile-container12">
                <h3 class="HeadingTwo">
              <span>
                {{ $t('contact.information.name') }}
              </span>
                </h3>
                <span class="profile-text27">
              {{ $t('contact.information.text-header') }}
            </span>
                <div class="profile-container13">
                  <div class="profile-container14">
                    <svg viewBox="0 0 1024 1024" class="profile-icon02">
                      <path
                          d="M742 460l-94-94q-18-18-10-44 24-72 24-152 0-18 12-30t30-12h150q18 0 30 12t12 30q0 300-213 513t-513 213q-18 0-30-12t-12-30v-150q0-18 12-30t30-12q80 0 152-24 24-10 44 10l94 94q186-96 282-282z"
                      ></path>
                    </svg>
                    <a href="tel:+420 778 033 333" class="profile-link Small">
                      +420 778 033 333
                    </a>
                  </div>
                  <div class="profile-container15">
                    <svg viewBox="0 0 1024 1024" class="profile-icon04">
                      <path
                          d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                      ></path>
                    </svg>
                    <a
                        href="mailto:info@translatecz.com?subject="
                        class="profile-link1 Small"
                    >
                      info@translatecz.com
                    </a>
                  </div>
                  <div class="profile-container16">
                    <svg viewBox="0 0 1024 1024" class="profile-icon06">
                      <path
                          d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"
                      ></path>
                    </svg>
                    <span class="profile-text28 Small">
                  Prague
                </span>
                  </div>
                </div>
                <div class="profile-container17">
                  <svg viewBox="0 0 877.7142857142857 1024" class="profile-icon08">
                    <path
                        d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
                    ></path>
                  </svg
                  >
                  <svg viewBox="0 0 950.8571428571428 1024" class="profile-icon10">
                    <path
                        d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                    ></path>
                  </svg
                  >
                  <svg viewBox="0 0 877.7142857142857 1024" class="profile-icon12">
                    <path
                        d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                    ></path>
                  </svg
                  >
                  <svg viewBox="0 0 877.7142857142857 1024" class="profile-icon14">
                    <path
                        d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                    ></path>
                  </svg>
                </div>
              </div>
              <img alt="image" src="/wave-1.svg" class="profile-image1"/>
              <div class="profile-container18"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
    <Modal
        v-show="isModalVisible"
        @close="closeModal"
    >
      <slot name="header">
      </slot>
      <template v-slot:body>
        <div style="white-space: break-spaces;" v-if="$i18n.locale==='en'">{{fileContentEn}}</div>
        <div style="white-space: break-spaces;" v-else>{{fileContentCz}}</div>
      </template>
      <template v-slot:footer>

      </template>
    </Modal>
  </div>
</template>


<script>
import AppHeader from '../components/header'
import FeatureCard from '../components/feature-card'
import TestimonialCard1 from '../components/testimonial-card1'
import AppFooter from '../components/footer'
import PrimaryBlueButton from "@/components/primary-blue-button.vue";
import Modal from '../components/modal'
import DataEn from 'raw-loader!../locales/data_en.txt'
import DataCz from 'raw-loader!../locales/data_cz.txt'
import {i18n} from '../main'

export default {
  name: 'Home',
  components: {
    AppHeader,
    FeatureCard,
    TestimonialCard1,
    AppFooter,
    PrimaryBlueButton,
    Modal,

  },
  data() {
    return {
      sliderValue: 0,
      formData: {
        name: '',
        email: '',
        phone: '',
        service: '',
        pageCount: '1',
      },
      isModalVisible: false,
      fileContentEn: DataEn,
      fileContentCz: DataCz,
    }
  },
  computed: {
    thumbStyle() {
      return {
        left: this.sliderValue,
      };
    },

  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async submitForm() {
      this.formData.pageCount = this.sliderValue;
      // You can use Axios or any other library to make an AJAX request to the server
      // and handle the response without reloading the page.
      // Here's a simple example using Axios.
      const corsOptions = {
        origin: 'http://localhost:8080/',
        optionsSuccessStatus: 200 // some legacy browsers (IE11, various SmartTVs) choke on 204
      }
      this.axios.post('https://send.pageclip.co/cBwUlEtle2Okh5ukoTVINsfrjsQ9KaHd/Translatecz', this.formData,{ crossdomain: true })
          .then(response => {
            // Handle the response as needed
            console.log('Form submitted successfully', response.data);
          })
          .catch(error => {
            // Handle any errors
            console.error('Form submission failed', error);
          });
    },

  },
  mounted() {

  },
  metaInfo: {
    title: 'Professional translation',
    meta: [
      {
        property: 'og:title',
        content: "Professional translation",
      },
    ],
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
  /*Adds smooth scrolling instead of snapping to element*/
}
a:hover {
  cursor: pointer;
  text-decoration: underline;
}
.profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: -30px;
}
.profile-image {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.grid1 {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  mix-blend-mode: lighten;
}

.grid1 div {
  border-radius: 40px;
  background: black;
  height: 15rem;
  /*  backdrop-filter: saturate(200%) blur(30px);
    background-color: hsla(0, 0%, 100%, .8);*/
  filter: grayscale(1);
}

.grid1 div:nth-of-type(2) {
  grid-column: 2 / 3;
}

.home-steps {
  color: var(--dl-color-gray-white)
}

.step-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  font-size: 15px;
  line-height: 1.55;
  margin-top: 15px;
}

.framer-1yj4cz1 {
  aspect-ratio: 2 / 1;
  border-radius: 40px;
  flex: 0 0 auto;
  height: 500px;
  mix-blend-mode: luminosity;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;
}

.framer-1p2r87r, .framer-1rv2ozr {
  place-self: start;
  border-radius: 40px;
  filter: grayscale(1);
  flex: 0 0 auto;
  grid-column: auto / span 2;
  height: 100%;
  mix-blend-mode: luminosity;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;
}

.framer-swesb8, .framer-39j2mo {
  place-self: start;
  border-radius: 40px;
  filter: grayscale(1);
  flex: 0 0 auto;
  height: 100%;
  mix-blend-mode: luminosity;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;
}

.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}

.home-container01 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  margin-top: 100px;
  z-index: 1;
  position: relative;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0, 0%, 100%, .8);
}

.home-text {
  text-align: center;
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text01 {
  color: var(--dl-color-secondary-700);
  align-self: flex-start;
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}

.home-container02 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}

.home-grid {
  flex: 1;
  width: 50%;
  height: 50%;
  display: grid;
  grid-gap: var(--dl-space-space-doubleunit);
  position: relative;
  transform: rotate(20deg);
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.home-image {
  width: 50%;
}

.home-image1 {
  width: 100%;
  margin-top: -200px;
}

.home-text02 {
  color: var(--dl-color-secondary-600);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
  margin-TOP: var(--dl-space-space-unit);
}

.home-image2 {
  top: 0px;
  left: auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 80vh;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}

.home-features {
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
}

.home-container03 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text03 {
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text04 {
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text05 {
  color: rgb(103, 116, 142);
  display: inline;
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}

.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}

.home-container05 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}

.home-text06 {
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text07 {
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text08 {
  margin: var(--dl-space-space-halfunit);
  font-style: normal;
  font-weight: 500;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.home-pricing {
  width: 100%;
  display: flex;
  padding: 48px;
  position: relative;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}

.home-card1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-gray-white);
  justify-content: space-between;
  width: 100%;
}

.home-container06 {
  display: flex;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
}

.home-text-price {
  margin: var(--dl-space-space-halfunit);
  font-style: normal;
  font-weight: 500;
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  font-size: 3rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text09 {
  text-align: right
}

.home-text10 {
  font-style: normal;
  font-weight: 400;
}

.home-card2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  justify-content: space-around;
  width: 100%;
}

.home-container07 {
  display: flex;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
}

.home-text14 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}

.home-text15 {
  margin: var(--dl-space-space-halfunit);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
}

.home-text16 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
}

.home-text17 {
  margin-left: 48px;
  margin-right: var(--dl-space-space-sixunits);
}

.home-text18 {
  font-style: normal;
  font-weight: 400;
}

.home-button {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}

.home-button:hover {
  transform: scale(1.02);
}

.home-card3 {
  display: flex;
  margin-top: 32px;
  align-items: center;
  padding-top: 48px;
  flex-direction: row;
  padding-bottom: 48px;
}

.home-container08 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.feature-card-container {
  flex-direction: row;
  width: 100%;
  margin-top: var(--dl-space-space-unitandahalfunit);
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}


.home-text22 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}

.home-text23 {
  margin: var(--dl-space-space-halfunit);
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
}

.home-text24 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
}

.home-text25 {
  margin-left: 48px;
  margin-right: var(--dl-space-space-sixunits);
}

.home-text26 {
  font-style: normal;
  font-weight: 400;
}

.home-button1 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}

.home-button1:hover {
  transform: scale(1.02);
}

.home-testimonials {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: 15rem;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: 15rem;
  justify-content: space-between;
}

.home-container10 {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}

.home-steps {
  width: 100%;
  display: flex;
  /*max-width: 1400px;*/
  align-items: center;
  flex-direction: column;
}

.home-text30 {
  color: var(--dl-color-gray-black);
  font-size: 3rem;
}

.home-text32 {
  color: var(--dl-color-gray-700);
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}

.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-step {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container13 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon {
  fill: var(--dl-color-gray-500);
  width: 110px;
  height: 110px;
}

.home-line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container14 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-align: center;
}

.home-line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container16 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon2 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container17 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text40 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line4 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container19 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon4 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line5 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container20 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text45 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text47 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}

.home-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container22 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon7 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container23 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text50 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text52 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}

.home-bottom-wave-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -5px;
  position: absolute;
  object-fit: cover;
}

.home-image3 {
  top: auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.home-top-wave-image {
  top: -5px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}

.home-contaier {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}

.home-container24 {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.service-card-container {
  margin-left: auto;
  margin-right: auto;
}

.home-text55 {
  margin-top: var(--dl-space-space-unitandahalfunit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-divider {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  margin-bottom: 4rem;
  flex-direction: column;
  /*backdrop-filter: saturate(200%) blur(30px);*/
  background-color: hsla(0, 0%, 100%, .8);
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;

  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  position: relative;
  margin-top: var(--dl-space-space-tripleunit);
}

.home-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home-text60 {
  font-size: 0.75rem;
  text-align: right;
  transform: translateY(100%);
  width: 100%;
}

.home-container26 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: var(--dl-space-space-doubleunit);
}

@media (max-width: 991px) {
  .home-hero {
  }

  .home-container01 {
    max-width: 960px;
    padding-top: var(--dl-space-space-tripleunit);
  }

  .home-card {
    width: 100%;
    margin-top: 40px;
  }

  .home-features {
    max-width: 960px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-container03 {
    max-width: 80%;
  }

  .home-text04 {
    text-align: center;
  }

  .home-container04 {
    max-width: 960px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-container05 {
    max-width: 80%;
  }

  .home-text07 {
    text-align: center;
  }

  .home-text09 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .home-text15 {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 1.5rem;
  }

  .home-text23 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    line-height: 1;
  }

  .home-container09 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    justify-content: center;
  }

  .home-container10 {
    align-items: center;
  }


  .home-contaier {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }

  .home-container26 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .home-card {
    align-items: center;
    margin-top: 40px;
  }
  .profile {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .home-text02 {
    color: var(--dl-color-secondary-600);
    text-align: center;
    margin-right: 0px;
    padding-right: 0px;
  }

  .home-image2 {
    display: none;
  }

  . home-text60 {
    text-align: center;
  }

  .service-card-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
    width: 400px;
  }

  .home-container04 {
    max-width: 720px;
  }

  .home-pricing {
    padding-left: 32px;
    padding-right: 32px;
  }

  .home-card1 {
    width: 100%;
    flex-direction: column;
  }

  .home-text09 {
    text-align: center;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;
  }

  .home-card2 {
    width: 100%;
    flex-direction: column;
  }

  .home-container09 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .home-steps {
    padding-left: 32px;
    padding-right: 32px;
  }

  .home-container11 {
    align-items: flex-start;
    padding-left: 80px;
    flex-direction: column;
  }

  .home-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }

  .home-container12 {
    width: 48px;
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 70px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }

  .step-text {
    text-align: right;
  }

  .home-line1 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-container14 {
    width: 100%;
    align-items: flex-end;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }

  .home-line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line3 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line5 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-container23 {
    width: 100%;
    align-items: flex-start;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }

  .home-container24 {
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-features {
  }

  .home-divider {
    margin-top: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }

  .profile-text18 {
    text-align: center;
  }
}

@media (max-width: 479px) {
  .home-hero {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }

  .profile {
    flex-direction: column;
  }

  .home-text15 {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 1.3rem;
  }

  .home-divider {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }

  .home-text60 {
    text-align: center;
    font-size: 0.65em;
  }

  .home-card {
    padding: var(--dl-space-space-unit);
  }

  .home-container02 {
    align-items: center;
    flex-direction: column;
  }

  .home-features {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }


  .HeadingThree {
    font-size: 1.23rem;
    font-family: Open Sans;
    font-weight: 500;
  }

  .home-container24 {
    flex-direction: column;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-pricing {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .home-container04 {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: var(--dl-space-space-doubleunit);
  }

  .home-text09 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }

  .home-text17 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }

  .home-container12 {
    width: 100px;
    padding-right: 0px;
  }

  .home-container09 {
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }

  .home-steps {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .home-container11 {
    padding-left: 0px;
  }

  .home-container14 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .home-text35 {
    margin-left: 0px;
    margin-right: 32px;
  }

  .home-container17 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }

  .home-text40 {
    margin-left: 0px;
    margin-right: 32px;
  }

  .home-container20 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }

  .home-text45 {
    margin-left: 0px;
    margin-right: 32px;
  }

  .home-container23 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }

  .home-text50 {
    margin-left: 0px;
    margin-right: 32px;
  }

  .feature-card-container {
    margin-bottom: 0;
    margin-top: var(--dl-space-space-unit);
  }
}


.profile-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: row;
  background-color: var(--dl-color-gray-white);
}

.profile-form {
  flex: 5;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}

.profile-text18 {
  color: var(--dl-color-secondary-400);
  margin-bottom: var(--dl-space-space-unit);
}

.profile-text20 {
  margin-bottom: var(--dl-space-space-doubleunit);
}

.profile-form1 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile-text21 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
}

.profile-textinput {
  width: 100%;
  height: 40px;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-secondary-100);
  padding-left: var(--dl-space-space-triplequarterunit);
  border-radius: var(--dl-radius-radius-radius5);
  padding-right: var(--dl-space-space-triplequarterunit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.profile-textinput:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #e9aede;
  border-color: var(--dl-color-pink-200);
}

.profile-text22 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
}

.profile-textinput1 {
  width: 100%;
  height: 40px;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-secondary-100);
  padding-left: var(--dl-space-space-triplequarterunit);
  border-radius: var(--dl-radius-radius-radius5);
  padding-right: var(--dl-space-space-triplequarterunit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.profile-textinput1:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #e9aede;
  border-color: var(--dl-color-pink-200);
}

.profile-text23 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
}

.profile-textarea {
  width: 100%;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-secondary-100);
  padding-left: var(--dl-space-space-triplequarterunit);
  border-radius: var(--dl-radius-radius-radius5);
  padding-right: var(--dl-space-space-triplequarterunit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.profile-textarea:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #e9aede;
  border-color: var(--dl-color-pink-200);
}

.profile-container10 {
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.profile-container11 {
  display: flex;
}

.profile-info {
  flex: 4;
  color: var(--dl-color-gray-white);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-sixunits);
  border-radius: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/curved5-1400w.jpg");
  border-top-right-radius: var(--dl-radius-radius-radius1);
  border-bottom-right-radius: var(--dl-radius-radius-radius1);
}

.profile-container12 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  z-index: 1;
  align-items: flex-start;
  flex-direction: column;
}

.profile-text27 {
  opacity: 0.8;
}

.profile-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}

.profile-container14 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.profile-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}

.profile-link {
  opacity: 0.8;
  text-decoration: none;
}

.profile-container15 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.profile-icon04 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}

.profile-link1 {
  opacity: 0.8;
  text-decoration: none;
}

.profile-container16 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.profile-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}

.profile-text28 {
  opacity: 0.8;
}

.profile-container17 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.profile-icon08 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.profile-icon10 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.profile-icon12 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.profile-icon14 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.profile-image1 {
  top: 0px;
  left: -1px;
  right: auto;
  width: 100px;
  z-index: 2;
  position: absolute;
  object-fit: cover;
}

.profile-container18 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  opacity: 0.9;
  position: absolute;
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

.profile-contact {
  width: 100%;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-sixunits);
}

@media (max-width: 991px) {
  .profile-container01 {
    margin-left: var(--dl-space-space-tripleunit);
    margin-right: var(--dl-space-space-tripleunit);
  }

  .profile {
    flex-direction: column;
  }

  .profile-container05 {
    width: 100%;
  }

  .profile-card {
    width: 100%;
  }

  .profile-container09 {
    flex-direction: column;
  }

  .profile-info {
    padding-left: 0px;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius1);
  }

  .profile-image1 {
    display: none;
  }
}

.t-cover {
  background-image: url(/background.jpg);
  /*height: 1300px;*/
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*padding-top: var(--dl-space-space-sixunits);*/
  /*padding-left: var(--dl-space-space-tripleunit);*/
  /*padding-right: var(--dl-space-space-tripleunit);*/
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.t-cover__carrier {
  -moz-transform: unset;
}

.t-cover__filter {
  background-color: rgb(41, 34, 28);
  opacity: 0.7;
  height: 95%;
  width: 100%;
  position: absolute;
}

.t-cover, .t-cover__carrier {
  height: 100%;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.t-select {
  width: 100%;
  cursor: pointer;
  height: 40px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-secondary-100);
  padding-left: var(--dl-space-space-triplequarterunit);
  border-radius: var(--dl-radius-radius-radius5);
  padding-right: var(--dl-space-space-triplequarterunit);
  padding-bottom: var(--dl-space-space-halfunit);
}

@media (max-width: 767px) {
  .home-text60 {
    text-align: center;
  }

  .profile-profile {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .profile-container01 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .profile-container02 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }

  .profile-text {
    margin-top: var(--dl-space-space-unit);
  }

  .profile-blog-posts {
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .profile-container05 {
    margin-top: var(--dl-space-space-doubleunit);
  }

  .profile-contact {
    padding: var(--dl-space-space-halfunit);
  }

  .profile-form {
    padding-top: var(--dl-space-space-doubleunit);
  }
}
</style>
