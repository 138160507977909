import { render, staticRenderFns } from "./primary-blue-button.vue?vue&type=template&id=4e077e92&scoped=true"
import script from "./primary-blue-button.vue?vue&type=script&lang=js"
export * from "./primary-blue-button.vue?vue&type=script&lang=js"
import style0 from "./primary-blue-button.vue?vue&type=style&index=0&id=4e077e92&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e077e92",
  null
  
)

export default component.exports