<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header">
          </slot>
          <button
              type="button"
              class="btn-close"
              @click="close"
          >
            X
          </button>
        </header>

        <section class="modal-body">
          <slot name="body">
            This is the default body!
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">

          </slot>
          <PrimaryPinkButton class=""
                             button="Close"
                             @click="close"
          ></PrimaryPinkButton>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

import PrimaryPinkButton from "@/components/primary-pink-button.vue";

export default {
  name: 'Modal',
  components: {PrimaryPinkButton},
  //components: {PrimaryPinkButton},
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.modal-backdrop {
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  max-height: 1130px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.btn {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}
@media (max-width: 991px) {
  .modal {
    max-width: 960px;
    max-height: 1130px;
  }
}

@media (max-width: 767px) {
  .modal {
    max-width: 70%;
    max-height: 80%;
  }
}

@media (max-width: 479px) {

}
</style>